import { cloneDeep, get, last, set as set0 } from "lodash";
import type { SectionContentDisplay, SectionContentItemsAccess } from "./types";

const privateKeyName = Symbol("__v_id");

export const useSectionContentStore = <T extends any>(id: string) => {
  const { wrapConfirm } = useConfirm();

  const s = defineStore(`section-content:${id}`, () => {
    const itemsAccess = shallowRef<SectionContentItemsAccess<T>>();
    const display = reactive<SectionContentDisplay>({
      addText: "",
    });
    const controls = reactive({
      tab: 0 as any,
      identify: false,
      sortable: false,
      itemTitle: "",
      itemValue: "",
      sortBy: "",
    });

    const setItemsAccess = (value: SectionContentItemsAccess<T>) => {
      itemsAccess.value = value;
    };

    const items = computed((): T[] => itemsAccess.value?.get() || []);

    const displayItems = computed(() => {
      return items.value.map((item) => ({
        item,
        title: itemTitle(item),
        key: getKey(item),
        valid: getValid(item),
      }));
    });

    const isEmpty = computed(() => !items.value.length);

    const getRawItems = (): T[] => {
      const items = itemsAccess.value?.get();
      return items ? cloneDeep(items) : [];
    };

    const setKey = (item: any) => {
      const keyName = controls.identify ? controls.itemValue : privateKeyName;
      const key = uuid();
      set0<any>(item, keyName, key);
      return key;
    };

    const getKey = (item: T) => {
      return get(item, privateKeyName) || get(item, controls.itemValue);
    };

    const add = () => {
      const x = getRawItems();
      const i = items.value.length;
      const n = itemsAccess.value!.make(i);
      const k = setKey(n);
      itemsAccess.value!.set([...x, n]);
      controls.tab = k;
    };

    const itemTitle = (item: T) => {
      const key = controls.itemTitle;
      return get(item, key);
    };

    const exists = (item: T) => {
      const fn = itemsAccess.value!.exists || (() => !!getKey(item));
      return fn(item);
    };

    const setValid = (item: T, valid: boolean) => {
      const key = getKey(item);
      const fn = itemsAccess.value!.setValid || (() => void 0);
      return fn(key, valid);
    };

    const getValid = (item: T) => {
      const key = getKey(item);
      if (itemsAccess.value!.getValid) {
        return itemsAccess.value!.getValid(key) !== false;
      }
      return true;
    };

    const remove = wrapConfirm(
      (item: T) => {
        const k = getKey(item);
        const i = items.value.findIndex((e) => getKey(e) == k);
        const x = getRawItems();
        deleteAt(x, i);
        setValid(item, true); // ensure references to item being removed only exists as valid
        itemsAccess.value!.set(x);
        if (controls.tab == k) {
          const n = last(x);
          controls.tab = n ? getKey(n) : 0;
        }
      },
      (item) => {
        if (!getValid(item)) {
          return true;
        }

        return {
          title: "Delete " + itemTitle(item),
        };
      }
    );

    const set = (items: T[]) => {
      itemsAccess.value!.set(items);
    };

    return {
      display,
      controls,
      isEmpty,
      items,
      displayItems,
      setItemsAccess,
      add,
      remove,
      setValid,
      getValid,
      set,
    };
  });

  return s();
};
